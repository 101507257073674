import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { wAlertFeatureKey, wAlertReduercer } from './reducers/w-alert.reducer';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { itemFeatureKey, itemReduercer } from './reducers/item.reducer';
import { NoticeLayoutComponent } from './layout/notice-layout/notice-layout.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RenderHtml } from './pipes/render-html.pipe';
import { PipeModule } from './pipes/pipe.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmPaymentComponent } from './layout/confirm-payment/confirm-payment.component';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    NoticeLayoutComponent,
    ConfirmPaymentComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PipeModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    NgxLoadingModule.forRoot({fullScreenBackdrop: true}),

    StoreModule.forFeature(itemFeatureKey, itemReduercer),
    StoreModule.forFeature(wAlertFeatureKey, wAlertReduercer),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
        positionClass: 'toast-bottom-right',
        maxOpened: 4
    })

  ],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'jp'}],
  bootstrap: [AppComponent],schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
