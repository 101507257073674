import {environment} from "@enviroments/environment";
import {SelectDropdownModel} from "@models/components/select-dropdown.model";

export class CommonConstant {
    static ON_DESTROY : string = "ON_DESTROY";

    // storage
    static CART_INFO_TMP : string = "cardInfoTmp";
    static LINK_CART : string = "linkCart";
    static LINK_CART_PRODUCTS : string  = "linkCartProducts";
    static LINK_GUEST : string = "linkGuest";
    static PRODUCT_RECENT : string = "pdtRecent";
    static LEN_CART : string = "lenCart";
    static PAGE_CART : string = "pageCart";
    static URL_WOWNET : string = environment.urlWownet;
    static CHECK_ITEM_PRODUCT : string = "checkItemProduct";
    static STR_IS_CHECK_REGISTER : string = "str_is_check_register";
    static USER_ID2 : string = "userid2";
    static CONSUMER : string = "consumer";
    static USER_ID : string = "userId";
	static FLAG_POINT : string = "flag_point";
	static GUEST_CART : string = "guest_cart";
	static MEMBER : string = "member";
	static ORDER_NO_TMP : string = "ord_no_tmp";
    static SHARE_URL : string = "shareURL";
	static WORK_KIND: string = "workKind";

    static REMAIN_PASSWORD: string = "remain_pass";
    static REMAIN_USER: string = "remain_user";

    // Common
    static YES = "Y";
    static NO = "N";
    static SLASH = "/";
	static SHARP = "#";
	static HYPHEN = "-";
    static PV1 = "pv1";
    static PV2 = "pv2";
    static PV3 = "pv3";
    static KOREA_CODE = "KR";
    static JAPAN_CODE = "JP";
    static BLANK = "";
    static LAST_MONTH = 11;
    static SELECT_LABEL = "선택";
	static SEARCH_PAST = "P";
	static SEARCH_FUTURE = "F";
	static SEARCH_NOW = "Y";
	static OK = "OK";

    static MAX_LENGTH_PRODUCT_BRAND = 3;
	static MAX_LENGTH_PRODUCT_NEW = 8 ;
	static TITLE_PAGE = "BPNU";

	static LIST_CART_MODAL = "addCartModal";
	static ORDER_MODAL = "orderModal";
	static MEMBER_MODAL = "memberModal";
	static ADO_MODAL = "adoModal";
	static ADO_PRODUCT_MODAL = "adoProductModal";
	static ZIP_MODAL = "zipModal";
	static PREPAY_MODAL = "prepayModal";
	static REGISTER_PREPAY_MODAL = "registerPrepayModal";
	static DELI_MODAL = "deliModal";
	static RESIDENT_MODAL = "residentModal";

	static SINGLE_DATE = "single";
	static RANGE_DATE = "range";

	static COMMON_FORMAT_DATE = "YYYYMMDD";

    static CATEGORY_MP_ID = "0221";
    static SORT_ASC = "asc";
    static SORT_DESC = "desc";


    static ADMIT_DAY_ADO_SELECT: SelectDropdownModel[] =
        [
            { value: "00", label: "일시불" },
            { value: "01", label: "01개월" },
            { value: "02", label: "02개월" },
            { value: "03", label: "03개월" },
            { value: "04", label: "04개월" },
            { value: "05", label: "05개월" },
            { value: "06", label: "06개월" },
            { value: "07", label: "07개월" },
            { value: "08", label: "08개월" },
            { value: "09", label: "09개월" },
            { value: "10", label: "10개월" },
            { value: "11", label: "11개월" },
            { value: "12", label: "12개월" },
        ];


}
