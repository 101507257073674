import { Component, OnInit } from '@angular/core';
import {MyProgramConstant} from "@app/common/constant/my-program.constant";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {ConvertUtil} from "@app/common/util/convert.util";
import {ValidationUtil} from "@app/common/util/validation.util";
import {setWAlert} from "@actions/w-alert.action";
import {WAlertConstant} from "@app/common/constant/w-alert-icon.constant";

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.css']
})
export class ConfirmPaymentComponent implements OnInit {


  orderId: string = '';
  status: string = '';
  resMsg: string = '';

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this._activatedRoute.queryParamMap.subscribe((params) => {
      this.status = params.get('status') || "";
      this.resMsg = params.get('resMsg') || "";
      this.orderId = params.get('orderId') || "";
    });

  }

  fuctionClose() {
    this._router.navigateByUrl(MyProgramConstant.urlShopping +`/cate/0211`);
  }

}
