
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeValue } from '@angular/platform-browser';

@Pipe({ name: "renderHtml" })
export class RenderHtml implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): SafeHtml {
    let trustedHtml: SafeValue;
    
    trustedHtml = this.sanitizer.bypassSecurityTrustHtml(value);
    return trustedHtml;
  }
}