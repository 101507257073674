
export const environment = {

  urlBetaIzex : "https://apl.beta-izex.ru/api/",
  keyBetaIzex : "88956aba576362ca65e2d1fcd3e9295a",
    urlWownet: 'https://v615.wownet.biz',

    production: false,
    default_lang: 'JP',
    comId: 'MEDIUSJP',
	comCd: '02',
    apiUrl: 'https://v615.wownet.biz/api'
};

