<div class="container">
    <ng-container *ngIf="status=='OK'">
        <div class="card">
            <h1  class="success">支払いが成功しました！</h1>
            <p style="color: #D28FBF">ご利用いただきありがとうございます。</p>
            <p style="color: #D28FBF">注文番号は <span> {{orderId}} </span> です。</p>
            <button class="btn-close-popup" (click)="fuctionClose()" >閉じる</button>
        </div>
    </ng-container>
    <ng-container *ngIf="status != 'OK'">
        <div  class="card-error">
            <h1 class="error">支払いに失敗しました</h1>
            <p style="color: #b10b0b">エラーが発生しました。</p>
            <button class="btn-close-popup-error"  (click)="fuctionClose()">閉じる</button>
        </div>
    </ng-container>
</div>
