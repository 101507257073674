
import { Pipe, PipeTransform } from '@angular/core';
import { LangConstant } from '@app/common/constant/lang.constant';
import { ValidationUtil } from '@app/common/util/validation.util';

@Pipe({ name: "stringDate" })
export class StringDatePipe implements PipeTransform {

  constructor() {}

  transform(value: string | String, lang: string, format: string | null): string {
    if (ValidationUtil.isNotNullAndNotEmpty(value) && value != 'NaN') {
      if (lang === LangConstant.LANG_KR || lang === LangConstant.KR) {
        if (ValidationUtil.isNotNullAndNotEmpty(format)) {
          return value.substring(0, 4) + format + value.substring(4, 6) + format + value.substring(6, 8);
        } else {
          return value.substring(0, 4) + "년" + value.substring(4, 6) + "월" + value.substring(6, 8) + "일";
        }
      }if (lang === LangConstant.LANG_JP || lang === LangConstant.JP) {
            if (ValidationUtil.isNotNullAndNotEmpty(format)) {
                return value.substring(0, 4) + format + value.substring(4, 6) + format + value.substring(6, 8);
            } else {
                return value.substring(0, 4) + "年" + value.substring(4, 6) + "月" + value.substring(6, 8) + "日";
            }
      }if (lang === 'JP2') {
            if (ValidationUtil.isNotNullAndNotEmpty(format)) {
                if (value.length > 8) {
                    value=value.replace(/[/\-]/g, '');
                }
                return value.substring(0, 4) + format + value.substring(4, 6) + format + value.substring(6, 8);
            } else {
                const year = value.substring(0, 4);
                const month = value.substring(4, 6);
                const day = value.substring(6, 8);

                const date = new Date(`${year}-${month}-${day}`);
                const dayOfWeek = date.getDay();
                const japaneseDaysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
                return value.substring(0, 4) + "-" + value.substring(4, 6) + "-" + value.substring(6, 8)+ " " + japaneseDaysOfWeek[dayOfWeek]+'曜日';
            }
      }if (lang === 'JP3') {
            if (ValidationUtil.isNotNullAndNotEmpty(format)) {
                return value.substring(0, 4) + format + value.substring(5, 7) + format + value.substring(8, 10);
            } else {
                return value.substring(0, 4) + "-" + value.substring(4, 6) + "-" + value.substring(6, 8);
            }
      }else {
        if (ValidationUtil.isNotNullAndNotEmpty(format)) {
          return value.substring(0, 4) + format + value.substring(4, 6) + format + value.substring(6, 8);
        } else {
          return value.substring(0, 4) + "-" + value.substring(4, 6) + "-" + value.substring(6, 8);
        }
      }
    }
    return "";
  }
}
